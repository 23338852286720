<template>
	<div>
		<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
	</div>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "Science",

	data() {
		return {
			options: [
				{
					title: "app.ko.science.databases.title",
					icon: "database",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.science.analyzing_experiment.title",
					icon: "experiment",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.science.analyzing_observation.title",
					icon: "observation",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.analyzing_famous_person.title",
					icon: "famous",
					route: {
						name: "AnalyzingPerson",
					},
					fade: true,
				},
				{
					title: "app.ko.decision_consideration.title",
					icon: "decision",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.analyzing_scientific_phenomenon.title",
					icon: "microscope",
					route: {
						name: "AnalyzingPhenomenon",
					},
					fade: true,
				},
				{
					title: "app.ko.point_of_view_and_beliefs.title",
					icon: "point-of-view",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.positive_negative.title",
					icon: "positive",
					route: {
						name: "PositiveNegative",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
